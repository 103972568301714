import React, { useState } from "react";
import Img from "gatsby-image";
import ContactFormModal from "./ContactFormModal";
import { parseContent } from "../helpers/city-pages";

const FooterLogoShowcase = ({ contentblockID, data }) => {
  const { footerlogoshowcase } = data;

  if (footerlogoshowcase && footerlogoshowcase.frontmatter && footerlogoshowcase.frontmatter.images) {
    return (
      <section className="h-auto bg-cover relative bg-brand-600" > 
        <div className="container mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <h2 class="font-bold text-xl md:text-2xl text-center text-gray-800 hidden">Highest Quality Brands &amp; Products</h2>
        <p class="text-center text-gray-600 text-lg hidden">Manufacturer warranties on parts, generous warranties on service.</p>
          <div
            className={`grid grid-cols-3 md:grid-cols-${footerlogoshowcase.frontmatter.images.length} gap-3 md:gap-5 text-center w-full px-4 py-2 `}
          >
            {footerlogoshowcase.frontmatter.images.map((item, i) =>{ 
                if (item.image && item.image.extension === "svg") {
                  return (
                    <div className="w-full flex items-center p-3" key={i}>
                    <img 
                      className="w-full max-h-60px"
                      style={{ 'maxHeight': "60px" }} 
                      src={item.image.publicURL}
                      alt={item.imgtitle}
                    />
                    </div>
                  );
                } else {
                  return  (
                    <div className="w-full flex items-center p-3" key={i}>
                      <Img
                        className="w-full max-h-60px"
                        style={{ maxHeight: "60px" }}
                        imgStyle={{ maxHeight: "60px", objectFit: "contain" }}
                        fluid={item.image && item.image.childImageSharp && item.image && item.image.childImageSharp.fluid}
                        alt={item.imgtitle}
                      />
                    </div>
                  )
                }

             })}
          </div>
        </div>
      </section>
    );
  }
  return null;
};

export default FooterLogoShowcase;

// md:grid-cols-1 md:grid-cols-2 md:grid-cols-3 md:grid-cols-4 md:grid-cols-5 md:grid-cols-6 md:grid-cols-7 md:grid-cols-8
