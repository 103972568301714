import React, { useState, useEffect, useCallback } from "react";
import Img from "gatsby-image";
import { StaticQuery, graphql, Link } from "gatsby";
import { dateOutputInXDays } from '../../helpers/dates'
const SidingOneThousandPromotion = () => (
  <StaticQuery
    query={graphql`
      query {
        fileName: file(relativePath: { eq: "house-1-home-exterior.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <div
          className={`rounded-lg mb-2 shadow bg-white`}
          style={{
            background: "linear-gradient(145deg, #24754b 50%, #F4F9FE 50%)",
          }}
        >
          <div className="px-8 pt-6 text-center">
            <h2 className="text-white font-bold text-3xl md:text-4xl">
              $1,000 OFF
            </h2>
            <h2 className="text-white font-bold text-lg md:text-xl leading-6">
              New House Siding Replacement
            </h2>
            <Link
              to={"/contact?ref=promo"}
              className="button-active-bhvr my-2 inline-block font-bold tracking-tight text-lg lg:text-xl cursor-pointer transition duration-150 ease-in-out text-brand-600 focus:text-brand-700  focus:outline-none px-5 py-2  rounded-full  bg-white shadow font-display"
            >
              Claim Offer
            </Link>
          </div>

          <div>
            <Img
              fluid={data.fileName.childImageSharp.fluid}
              alt="New House Siding Replacement"
            />
            <p className="text-sm text-center text-gray-500 -mt-2 py-2 italic">
              *Offer expires {dateOutputInXDays(6)}
            </p>
          </div>
        </div>
      );
    }}
  />
);

export default SidingOneThousandPromotion;
