import React from "react";
import ReviewsSidebarCarousel from './ReviewsSidebarCarousel'
import FiftyOffWaterHeaterRepair from './coupons/FiftyOffWaterHeaterRepair'
import SidingOneThousandPromotion from './coupons/SidingOneThousandPromotion'

const DynamicSidebarController = ({ dynamicsidebarid, frontmatter }) => {
 

  switch (dynamicsidebarid) {
    case "reviews_carousel":
      return (
        <ReviewsSidebarCarousel frontmatter={frontmatter}/>
      );
    case "150OFFTANKLESSREPLACEMENTUPGRADE":
      return <SidingOneThousandPromotion/>
    case "50OFFWATERHEATERREPLACEMENT":
      return <FiftyOffWaterHeaterRepair/>
    default:
      return null;
  }


};

export default DynamicSidebarController;
