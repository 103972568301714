import React from "react";
import ReviewTilesComponent from './ReviewTilesComponent' 
const DynamicReactSectionsController = ({ data }) => {
  let moduleName = data && data.value 

  switch (moduleName) {
    case "reputationtiles":
      return (
        <ReviewTilesComponent/>
      ); 
    default:
      return null;
  }


};

export default DynamicReactSectionsController;
